@import url("https://use.typekit.net/qhl1swr.css");

.flex-card {
    height: 100%;
    position: relative;
    display: flex;
}

.flex-card .mantine-Card-cardSection {
    padding: 10px;
    display: flex;
}

.mantine-Stepper-steps > button {
    outline: none !important;
}


button.mantine-Stepper-step[disabled] {
    cursor: not-allowed;
}

.randomColorAvatar .mantine-Avatar-placeholder {
    color: inherit;
    background-color: inherit;
}

.videoCardBefore:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 0px;
    background: rgba(0, 0, 0, 0.6);
    z-index: 2;
    transition: 0.5s;
    opacity: 0;
}

.videoCardBefore:hover:before {
    opacity: 1;
}

.button {
    padding: 0.6rem;
    outline: none;
    border: none;
    border-radius: 3px;
    background: white;
    color: black;
    font-weight: bold;
    cursor: pointer;
    transition: 0.4s ease;
}

.button:hover {
    background: dodgerblue;
    color: white;
}

.info {
    position: relative;
    z-index: 3;
    color: white;
    transition: 0.5s ease all;
    opacity: 0;
}

.videoCardBefore:hover .info {
    opacity: 1;
    transform: translateY(-20px);
}
