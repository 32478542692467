.vi__wrapper {
    width: 100%;
}

.vi__container {
    margin: auto;
}

.vi__character {
    border: 0.0625rem solid #ced4da;
    border-radius: 0.25rem;
}

.fullWidthLabel label {
    width: 100%;
}